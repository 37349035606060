import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { MenuItem } from 'primeng/api';

import { CompactModeService } from 'src/app/compact-mode.service';

import { HeaderAuthService } from './header-auth.service';
import { HeaderAnnouncementService } from './header-announcement.service';
import { HeaderLogoService } from './header-logo.service';
import { HeaderNotificationService } from './header-notification.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit, OnDestroy {
  compactMode: boolean = false;
  private compactModeSubscription!: Subscription;

  showNotificationsSideBar: boolean = false;

  authValidatedSubscription!: Subscription;

  constructor(
    private headerAuthService: HeaderAuthService,
    public readonly headerLogoService: HeaderLogoService,
    public readonly headerAnnouncementService: HeaderAnnouncementService,
    public readonly headerNotifService: HeaderNotificationService,
    private compactModeService: CompactModeService
  ) {}

  get notifications() {
    return this.headerNotifService.notifications;
  }

  get showNotice() {
    return this.headerAuthService.showNotice;
  }

  get announcementDetails() {
    return this.headerAnnouncementService.announcementDetails;
  }

  public items$: Observable<MenuItem[]> = this.headerAuthService.verifiedUserRoles$.pipe(
    map((userRoleDetail) => this.headerAuthService.getHeaderMenu(userRoleDetail))
  );

  ngOnInit(): void {
    // console.log('HeaderComponent - ngOnInit');
    this.compactModeSubscription = this.compactModeService.getCompactMode().subscribe((compactMode) => {
      this.compactMode = compactMode;
    });
    this.headerLogoService.configureLogo();
    this.headerAuthService.configureAuthentication();

    this.authValidatedSubscription = this.headerAuthService.authValidated$.subscribe((val: string) => {
      // configure all required params post authentication
      // console.log(val);
      if (val) {
        this.headerNotifService.destroyConfiguration();
        this.headerNotifService.configureWebSockets(val);
        this.headerAnnouncementService.configureAnnouncements();
      } else {
        this.showNotificationsSideBar = false;
        this.headerNotifService.destroyConfiguration();
      }
    });
  }

  toggleNotificationsSidebar = () => {
    this.showNotificationsSideBar = !this.showNotificationsSideBar;
  };

  setCompactMode() {
    this.compactModeService.setCompactMode(this.compactMode);
  }

  ngOnDestroy() {
    // console.log('HeaderComponent - ngOnDestroy');
    this.compactModeSubscription?.unsubscribe();
    this.headerLogoService.destroyConfiguration();
    this.headerNotifService.destroyConfiguration();
    this.headerAuthService.destroyConfiguration();
    this.headerAnnouncementService.destroyConfiguration();
  }

  /**
   * This controls whether the whole component should be displayed at all.
   */
  get shouldDisplay(): boolean {
    return this.headerAuthService.isLoggedIn && !location.pathname.endsWith('resetPassword');
  }
}
