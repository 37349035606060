import { Injectable } from '@angular/core';
import { Subscription } from 'rxjs';
import { NavigationStart, Router } from '@angular/router';
import { SystemAnnouncementService } from 'src/app/system-announcements/system-announcements.service';
import { HeaderAnnouncement, UserAnnouncement } from './header.types';

export const defaultAnnouncement = {
  dismissedAnnouncement: true,
  latestAnnouncementId: '',
  latestAnnnouncementTitle: ''
};

@Injectable()
export class HeaderAnnouncementService {
  announcementDetails: HeaderAnnouncement = { ...defaultAnnouncement };

  private announcementSubscription!: Subscription;

  constructor(private route: Router, private announcementService: SystemAnnouncementService) {}

  get showAnnouncement() {
    const isDashbord =
      !this.announcementDetails.dismissedAnnouncement &&
      (location.pathname.startsWith('/corporate-actions-view') || location.pathname === '/portfolios');
    return isDashbord;
  }

  configureAnnouncements = () => {
    // console.log('HeaderAnnouncementService - configureAnnouncements');
    this.fetchLatestAnnouncement();
    this.announcementSubscription = this.route.events.subscribe((val) => {
      if (val instanceof NavigationStart) {
        if (val.url.startsWith('/corporate-actions-view') || val.url === '/portfolios') {
          this.fetchLatestAnnouncement();
        }
      }
    });
  };

  private fetchLatestAnnouncement = () => {
    this.announcementService.fetchLatestAnnouncement().subscribe({
      next: (data: any) => {
        const { isLastAnnouncementDismissed, lastAnnnouncementId, lastAnnnouncementTitle } = data as UserAnnouncement;
        this.announcementDetails = {
          dismissedAnnouncement: isLastAnnouncementDismissed,
          latestAnnouncementId: lastAnnnouncementId,
          latestAnnnouncementTitle: lastAnnnouncementTitle
        };
      },
      error: (err) => {
        this.announcementDetails = { ...defaultAnnouncement };
      }
    });
  };

  dismissAnnouncement = () => {
    const announcementId = this.announcementDetails.latestAnnouncementId;
    this.announcementService.dismissAnnouncement(announcementId).subscribe({
      next: (data: any) => {
        this.announcementDetails = { ...defaultAnnouncement };
      },
      error: (err) => {
        this.announcementDetails = { ...defaultAnnouncement };
      }
    });
  };

  destroyConfiguration() {
    // console.log('HeaderAnnouncementService - destroyConfiguration');
    this.announcementSubscription?.unsubscribe();
  }
}
