import { Component, EventEmitter, Input, Output } from '@angular/core';
import { INotification } from '../header.types';

@Component({
  selector: 'app-notification-list',
  templateUrl: './notification-list.component.html',
  styleUrls: ['./notification-list.component.css']
})
export class NotificationListComponent {
  @Input() sidebarVisible: boolean = false;

  @Input() notifications: INotification[] = [];
  @Input() closeSideBar: Function = () => {};
  @Output() notificationDismissed = new EventEmitter<string>();
  @Output() allNotificationDissmissed = new EventEmitter<void>();

  dismissNotification(notificationId: string) {
    this.notificationDismissed.emit(notificationId);
  }

  dismissAllNotifications() {
    this.allNotificationDissmissed.emit();
  }

  jsonToString = (value: any) => {
    return value ? JSON.stringify(value) : '';
  };

  isFailedAndHasErrorMsg = (item: INotification) => {
    const content = item.content || {};
    return !!(content.status?.toUpperCase() === 'FAILED' && content.message);
  };
}
