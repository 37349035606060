import { Injectable } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

import { AuthProviderService } from 'src/app/-services-/auth-framework/auth-provider.service';
import { CaViewer, TaxManger } from 'src/app/-services-/auth-framework/api-constants/applications/application-instances';
import {
  AdministratorRole,
  AdvisorRole,
  BackofficeAdminRole,
  BackofficeRole,
  CompanyManagerRole,
  SiteAdminRole
} from 'src/app/-services-/auth-framework/api-constants/roles/role-instances';

import { IMessage, IUserRoleDetail } from './header.types';

@Injectable()
export class HeaderAuthService {
  public authValidated$ = new BehaviorSubject<string>('');

  constructor(private authService: AuthProviderService) {}

  private authenticatedSubscription!: Subscription;

  verifiedUserRoles$ = this.authService.account$.pipe(
    map(() => ({
      isTaxManagerView: this.authService.verifyUserApplication(TaxManger),
      canViewActivities: [SiteAdminRole, AdministratorRole, CompanyManagerRole, BackofficeAdminRole, BackofficeRole, AdvisorRole].some((role) =>
        this.authService.verifyUserRole(role)
      ),
      canViewExternalData: [SiteAdminRole, AdministratorRole, CompanyManagerRole, BackofficeAdminRole, BackofficeRole].some((role) =>
        this.authService.verifyUserRole(role)
      ),
      canViewUserSecurityMgmt: [SiteAdminRole, AdministratorRole, CompanyManagerRole, BackofficeAdminRole, BackofficeRole].some((role) =>
        this.authService.verifyUserRole(role)
      ),
      canViewReports: [SiteAdminRole, AdministratorRole, CompanyManagerRole, BackofficeAdminRole, BackofficeRole, AdvisorRole].some((role) =>
        this.authService.verifyUserRole(role)
      )
    }))
  );

  get isLoggedIn() {
    return this.authService.isLoggedIn;
  }

  get showNotice() {
    const isCaViewer = this.authService.verifyUserApplication(CaViewer);
    const isTaxManager = this.authService.verifyUserApplication(TaxManger);
    return isCaViewer && !isTaxManager;
  }

  configureAuthentication = () => {
    this.authenticatedSubscription = this.authService.isAuthenticated().subscribe((isLoggedIn) => {
      //this.msgService.add({detail: "Logged in event recvd"});
      const token = this.authService.getAccessToken();
      if (isLoggedIn && token) {
        this.authValidated$.next(token);
        // console.log('authentication validated');
      } else {
        this.authValidated$.next('');
        // console.log('authentication NOT validated');
      }
    });
  };

  getHeaderMenu = ({ isTaxManagerView, canViewActivities, canViewExternalData, canViewUserSecurityMgmt, canViewReports }: IUserRoleDetail) => {
    return isTaxManagerView
      ? [
          {
            label: 'Home',
            icon: 'pi pi-fw pi-home',
            routerLink: '/portfolios'
          },
          {
            label: 'Activities',
            icon: 'pi pi-fw pi-calendar',
            routerLink: 'activities',
            visible: canViewActivities
          },
          {
            label: 'External data',
            icon: 'pi pi-fw pi-globe',
            routerLink: 'external-data',
            visible: canViewExternalData
          },
          {
            label: 'User Security',
            icon: 'pi pi-fw pi-id-card',
            routerLink: 'user-security-management',
            visible: canViewUserSecurityMgmt
          },
          {
            label: 'Reports',
            icon: 'pi pi-fw pi-chart-pie',
            routerLink: 'reports',
            visible: canViewReports
          }
        ]
      : [
          {
            label: 'Home',
            icon: 'pi pi-fw pi-home',
            routerLink: '/'
          }
        ];
  };

  destroyConfiguration() {
    this.authenticatedSubscription?.unsubscribe();
  }
}
