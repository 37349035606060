import { Injectable } from '@angular/core';
import { WebSocketSubject } from 'rxjs/webSocket';
import { v4 as uuidv4 } from 'uuid';
import { MessageService } from 'primeng/api';

import { environment } from 'src/environments/environment';

import { PortfolioRefreshService } from 'src/app/services/portfolio-stats-refresh.service';
import { RegistryRefreshService } from 'src/app/services/holder-registry-refresh.service';
import { UnmappedDocCountRefreshService } from 'src/app/services/unmapped-doc-count-refresh.service';

import { NOTIF_TYPES } from './notification-list/notification.types';
import { getNotificationDetails } from './header-notification.utilities';
import { HeaderService } from './header.service';
import { IMessage, INotification } from './header.types';

@Injectable()
export class HeaderNotificationService {
  webSocket: WebSocketSubject<any> | undefined;

  notifications: INotification[] = [];
  silentNotificationTypes = [NOTIF_TYPES.HOLDER_DOWNLOAD, NOTIF_TYPES.UNMAPPED_DOC_COUNT];

  constructor(
    private readonly portfolioRefreshService: PortfolioRefreshService,
    private readonly registryRefreshService: RegistryRefreshService,
    private readonly unmappedDocCountRefreshService: UnmappedDocCountRefreshService,
    private headerService: HeaderService,

    private readonly msgService: MessageService
  ) {}

  configureWebSockets = (token: string) => {
    // //this.msgService.add({detail: "New websocket established"});
    const WS_URL = environment.envVar.API_BASE_WS_URL;
    this.webSocket = new WebSocketSubject(`${WS_URL}?token=${token}`);
    this.webSocket.subscribe((message: IMessage) => {
      this.handleNotification(message);
    });
  };

  handleNotification = (message: IMessage) => {
    const notificationType = message.type;
    // Only allow non silent notifications to be updated in array
    if (!this.silentNotificationTypes.includes(notificationType)) {
      this.notifications = [
        ...this.notifications,
        {
          notificationId: uuidv4(),
          read: false,
          ...message,
          date: new Date()
        } as INotification
      ];
    }

    // send this to a refresh service
    if (notificationType === NOTIF_TYPES.PORTFOLIO_PROCESSING) {
      this.portfolioRefreshService.sendMessage(message.content);
    }

    if (notificationType === NOTIF_TYPES.HOLDER_DOWNLOAD) {
      this.registryRefreshService.sendMessage(message.content?.status);
    }
    if (notificationType === NOTIF_TYPES.UNMAPPED_DOC_COUNT) {
      this.unmappedDocCountRefreshService.sendMessage(message.content?.unmappedDocCount || 0);
    }
    // Handle additional toast's for notification
    this.showToastMsg(message);
  };

  showToastMsg = (message: IMessage) => {
    const { showNotification, notificationObj, url } = getNotificationDetails(message);
    if (showNotification) {
      this.msgService.add(notificationObj);
    }
    if (url) {
      this.downloadExcelFile(url);
    }
  };

  downloadExcelFile(downloadUrl: string) {
    const urlHost = downloadUrl?.split('?')?.[0] || '';
    const fileNameInUrl = urlHost?.split('/').pop() || '';
    let fileName = decodeURIComponent(fileNameInUrl);

    // Sanitize the fileName to remove potentially malicious characters
    fileName = fileName.replace(/[^a-z0-9_\-\.]/gi, '_');

    const defaultName = `Report_${Date.now()}`;
    this.headerService.downloadExcel(downloadUrl).subscribe((data: Blob) => {
      const url = window.URL.createObjectURL(data);
      const anchor = document.createElement('a');
      anchor.href = url;

      // Encode the fileName to prevent XSS
      anchor.setAttribute('download', encodeURIComponent(fileName || defaultName));

      anchor.style.display = 'none';
      document.body.appendChild(anchor);
      anchor.click();
      document.body.removeChild(anchor);
    });
  }

  dismissNotification(notificationId: any) {
    // Find the index of the notification with the given ID
    const index = this.notifications.findIndex((notification) => notification.notificationId === notificationId);
    if (index !== -1) {
      // Remove the notification from the array
      this.notifications.splice(index, 1);
    }
  }

  dismissAllNotifications() {
    this.notifications = [];
  }

  destroyConfiguration() {
    this.webSocket?.complete();
  }
}
