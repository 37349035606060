// @ts-nocheck

import { Component, OnInit } from '@angular/core';
import { AuthProviderService } from '../../../../-services-/auth-framework/auth-provider.service';
import { Router } from '@angular/router';
import { MenuItem } from 'primeng/api/menuitem';
import { MessageService, PrimeIcons } from 'primeng/api';
import { swalConfirm } from '../../../util/swal-mixins/native-fn-replacements';
import DOMPurify from 'dompurify';
import { Actions } from 'src/app/store';
import { HttpClient } from '@angular/common/http';
import { AwsCognitoService } from 'src/app/services/aws-cognito.service';

interface IUserMetaData {
  firstName: string;
  companyName: string;
  lastName: string;
  email: string;
}

@Component({
  selector: 'app-profile-button-loggedin',
  templateUrl: './profile-button-loggedin.component.html',
  styleUrls: ['./profile-button-loggedin.component.css']
})
export class ProfileButtonLoggedinComponent implements OnInit {
  constructor(
    public authProvider: AuthProviderService,
    private router: Router,
    public readonly actionsService: Actions,
    private http: HttpClient,
    private messageService: MessageService
  ) {}

  private userMetaDataApiUrl = 'api/users/byId';

  modalOpen = false;
  isLoading: boolean = false;
  companyName!: string;
  menuItems: MenuItem[] = [
    { label: "You're logged in.", disabled: true },
    {
      label: 'Profile',
      icon: PrimeIcons.USER,
      command: () => this.goToProfilePage()
    },
    {
      label: 'User Manual',
      icon: PrimeIcons.BOOK,
      routerLink: 'user-manual'
    },
    {
      label: 'Give Feedback',
      icon: PrimeIcons.SEND,
      command: () => this.openFeedbackModal()
    },
    {
      label: 'Announcements',
      icon: PrimeIcons.MEGAPHONE,
      routerLink: 'announcements'
    },
    {
      label: 'About',
      icon: PrimeIcons.INFO_CIRCLE,
      routerLink: 'about'
    },
    {
      label: 'Log Out',
      icon: PrimeIcons.SIGN_OUT,
      command: (e) => this.requestLogOut()
    }
  ];

  openFeedbackModal() {
    this.modalOpen = true;
  }

  async requestLogOut() {
    if (await swalConfirm('Are you sure you want to log out?')) {
      //save last url
      // const lastWorkingPath = this.router.url;
      const lastWorkingPath = this.router.routerState.snapshot.url;
      this.actionsService.setStore({ lastWorkingPath });

      //check if the user is logged in via cognito
      await this.authProvider.logOut(false);
    }
  }

  goToProfilePage = async () => {
    const userId = this.authProvider.account.id;
    await this.router.navigate(['users', 'profile', userId]);
  };

  ngOnInit(): void {
    this.fetchUserInfo();
  }

  getUserMetaData(searchText: string): Observable<IUserMetaData[]> {
    return this.http.get<IUserMetaData[]>(`${this.userMetaDataApiUrl}/${this.authProvider.account?.id}`);
  }

  fetchUserInfo() {
    this.isLoading = true;
    this.getUserMetaData().subscribe({
      next: (res) => {
        this.isLoading = false;
        this.companyName = this.authProvider.account?.organisationCode;
        this.email = res.userEmail;
        this.firstName = res.firstName;
        this.lastName = res.lastName;
      },
      error: (error) => {
        this.isLoading = false;
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Failed to get user data.'
        });
      }
    });
  }

  /**
   * Adaptively get the user's initials, from account's firstName and lastName fields.
   * If firstName and lastName fields are not available, tries to parse initials from the user's email.
   */
  computeInitials() {
    const firstName = this.authProvider.account?.firstName || this.firstName;
    const lastName = this.authProvider.account?.lastName || this.lastName;

    let nameInitials = `${firstName?.trim().charAt(0) || ''}${lastName?.trim().charAt(0) || ''}`;
    if (!nameInitials) {
      const emailName = this.email?.split('@').slice(0, 1)[0].split('.').slice(0, 2) || '';
      nameInitials = `${emailName[0]?.charAt(0) || ''}${emailName[1]?.charAt(0) || ''}`;
    }
    return nameInitials.toUpperCase();
  }

  generateTooltipHTML() {
    const firstName = this.authProvider.account?.firstName || this.firstName;
    const lastName = this.authProvider.account?.lastName || this.lastName;
    const fullUserName = `${firstName || ''} ${lastName || ''}`.trim();
    const assembledString = `<strong>${this.companyName}</strong><br />${fullUserName || this.email}`;
    return DOMPurify.sanitize(assembledString);
  }
}
