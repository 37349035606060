import { Injectable } from '@angular/core';
import { LogoSrcLoader } from './-services-/LogoSrcLoader';
import { AuthProviderService } from 'src/app/-services-/auth-framework/auth-provider.service';

@Injectable()
export class HeaderLogoService {
  logoSrc = '';
  logoSrcLoader: LogoSrcLoader;

  constructor(authService: AuthProviderService) {
    this.logoSrcLoader = new LogoSrcLoader(this, authService);
  }

  configureLogo = () => {
    // console.log('HeaderLogoService - configureLogo');
    this.logoSrcLoader.setUp();
  };

  destroyConfiguration = () => {
    // console.log('HeaderLogoService - destroyConfiguration');
    this.logoSrcLoader.tearDown();
  };
}
